var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"vue-family-tree",style:(Object.assign({}, {overflow: _vm.enableDrag ? 'hidden' : null,
    cursor: _vm.dragAndDrop.mouseCursor},
    _vm.wrapperStyles)),on:{"mousedown":_vm.dragstart,"mousemove":_vm.drag,"mouseup":_vm.dragend,"mouseleave":_vm.dragend,"touchstart":_vm.dragstart,"touchmove":_vm.drag,"touchend":_vm.dragend,"touchcancel":_vm.dragend,"touchleave":_vm.dragend}},[_c('div',{ref:"vueFamilyTree",style:({
      position: _vm.enableDrag ? 'absolute' : null,
      top: ((_vm.position.y) + "px"),
      left: ((_vm.position.x) + "px"),
    })},[_c('VueFamilyTreeBranch',{attrs:{"tree":_vm.tree},on:{"card-click":_vm.cardClick},scopedSlots:_vm._u([{key:"card",fn:function(ref){
    var item = ref.item;
return [_vm._t("card",null,{"item":item})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }