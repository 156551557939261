import request from '@/utils/request'
import ENDPOINT from '../config/endpoint'

export function createHierarchyItem(data) {
  return request({
    url: ENDPOINT.HIERARCHY_ITEM,
    method: 'post',
    data
  })
}

export function updateHierarchyItem(data) {
  return request({
    url: ENDPOINT.HIERARCHY_ITEM,
    method: 'patch',
    data
  })
}

export function getHierarchyItem(params) {
  return request({
    url: ENDPOINT.HIERARCHY_ITEM,
    method: 'get',
    params
  })
}

export function deleteHierarchyItem(params) {
  return request({
    url: ENDPOINT.HIERARCHY_ITEM,
    method: 'delete',
    params
  })
}
