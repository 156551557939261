<template>
  <el-card
    class="hierarchy-item"
    :body-style="{
      padding: data.based_id ? '0px' : '12px',
      width: '100%'
    }"
  >
    <img
      v-if="data.based_id"
      :src="
        data.avatar && data.avatar.url ? data.avatar.url : imageDefaultUrl
      "
      alt="href"
    />
    <div v-if="data.based_id" class="hierarchy-item__info">
      <p class="m-0 text-primary" :title="data.name">
        <router-link :to="getToShowRouter({ id: data.based_id }, data.place_type)">{{ data.name }}</router-link>
      </p>
      <div class="create-children">
        <div v-if="!data.link_path" class="cursor-pointer" style="font-size: 13px;" @click="$emit('add-children', data)">
          <i class="el-icon-plus mr-1"></i>Thêm mới
        </div>
        <div v-if="data.path && data.path != 1" class="cursor-pointer" @click="$emit('edit')">
          <i class="text-primary el-icon-edit"></i>
        </div>
      </div>
    </div>
    <el-row v-else class="w-100 text-primary" type="flex" align="middle" justify="center">
      <b>{{ data.name }}</b>
    </el-row>
  </el-card>
</template>

<script>
import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'HierarchyItem',
  props: {
    data: Object
  },
  data () {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  methods: {
    getToShowRouter
  }
}
</script>

<style lang="scss">
  .hierarchy-item {
    width: 100%;
    min-height: 56px;
    display: flex;
    border-radius: 8px;
    .el-card__body {
      display: flex;
    }
    img {
      width: 56px !important;
      height: 100% !important;
      object-fit: cover;
    }
    &__info {
      padding: 8px;
      flex: 1;
      p {
        font-size: 14px;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        line-height: 24px;
      }
      .create-children {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>
